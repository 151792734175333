.bubbleDropdown {
  position: relative;
  display: inline-block;
}
.link {
  height: 100%;
}
.dropdownToggle {
  cursor: pointer;
  background: transparent;
  &:before {
    display: block;
    content: '';
    z-index: 998;
    position: absolute;
    width: 200%;
    height: 40px;
  }
}

.dropdownMenu {
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 999;
  background: var(--color_20);
  margin-top: 5px;
  list-style-type: none;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #4a4f58;
  width: 180px;
  overflow: hidden;
}

.dropdownMenu li {
  text-align: left;
  a {
    display: block;
    padding: 24px 16px;

  }
}

.dropdownMenu li:not(:last-child) {
  border-bottom: 1px solid #4a4f58;
}

.dropdownMenu li:hover {
  background: var(--color_30);
}
