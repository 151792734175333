.header {
  height: 70px;
  padding: 0 16px;
  display: flex;
  position: relative;
  align-items: center;
  background: var(--color_10);
  justify-content: space-between;
  width: 100%;
  z-index: 999;
  .logo {
    font-size: 0;
  }
  .h5_logo{
    display: none;
  }
  .nav_logo{
    display: block;
  }
  /* H5 样式 */
  @media screen and (max-width: 768px) {
    padding: 0 8px;
    height: 44px;
    position: fixed;
    top: 0;
    left: 0;
    .logo {
      width: 22px;
      height: 22px;
    }
    .h5_logo{
      display: block;
    }
    .nav_logo{
      display: none;
    }
  }

  /* iPad 样式 */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 0 12px;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
  }
}

