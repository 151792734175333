.nav {
  display: flex;
  gap: 44px;
  flex: 1;
  font-size: 16px;
  padding-left: 60px;
  height: 44px;
  line-height: 44px;
  align-items: center;
  & > a {
    color: var(--color_90);
    transition: all 0.4s;
  }
  & a:hover {
    color: var(--color_180);
  }
  .subPathText {
    width: 30px;
    gap: 4px;
    white-space: nowrap;
  }
  a.active {
    color: var(--color_200);
    font-weight: 600;
  }
  .icon{
    width: 24px;
    height: 24px;
    margin-left: 7px;
    position: absolute;
    top: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .nav {
    gap: 24px;
    padding-left: 24px;
    font-size: 14px;

  }
}